@import '/src/styles/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/variables';

.TimelineHorizontal {
  

  .journey-step {
    flex: 1 1 0px;
    &:before {
      content: '';
      display: block;
      height: 20px;
      width: 20px;
      background-color: $secondary;
      margin: 1rem auto;
      border-radius: 100%;
      border: 3px solid darken($secondary, 5%);
      position: relative;
      z-index: 1;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 23px;
      height: 2px;
      width: 100%;
      z-index: 0;
      border: 2px solid $secondary;
    }

    &.journey-destination {
      &:not(.journey-complete):before {
        background-color: #e20000;
        border: 3px solid darken(#e20000, 10%);
      }
      &:not(.journey-complete):after {
        border: 2px dashed $gray-300;
      }

      &:after {
        left: 0;
        width: 50%;
      }
    }

    &.journey-origin {
      &:after {
        left: 50%;
        width: 50%;
        
      }
    }
  }
}

