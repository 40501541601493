@import '/src/styles/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/variables';

.Timeline {
  .journey-step {
    padding-left: 2rem;
  
    &:before {
      content: '';
      display: block;
      height: 20px;
      width: 20px;
      background-color: $secondary;
      position: absolute;
      top: .15rem;
      left: 0rem;
      border-radius: 100%;
      border: 3px solid darken($secondary, 5%);
    }
  
    &.journey-destination {
      &:not(.journey-complete):before {
        background-color: #e20000;
        border: 3px solid darken(#e20000, 10%);
      }
      &:not(.journey-complete):after {
        border: 2px dashed $gray-300;
      }
    }
  
    &.journey-origin {
      &:after {
        display: none;
      }
    }
  
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 23px;
      left: 8px;
      height: calc(100% + 3rem);
      width: 2px;
      border: 2px solid $secondary;
    }
  }
}