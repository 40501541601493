// Required
@import "~bootstrap/scss/functions";

// Default variable overrides
@import './variables';

// Required
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


// Bootstrap Modules
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/spinners";
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/accordion';

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/utilities/api";


// Import Icons
@import '~material-design-icons/iconfont/material-icons';
// Icon sizes
.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }