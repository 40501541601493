.pointer-all {
  pointer-events: all;
}

.bg-off-white {
  background-color: $color-off-white !important;
}
.bg-gray-100 {
  background-color: $gray-100;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  overflow: auto;
}